'use client'
import { Dispatch, ReactNode, SetStateAction } from 'react'
import { DialogRadixAdapter } from '@/infra/dialog'

interface RootProps {
  open?: boolean
  onOpenChange?: Dispatch<SetStateAction<boolean>> | (() => void)
  children: ReactNode
}

export function Root({ open, onOpenChange, children }: RootProps) {
  const Dialog = new DialogRadixAdapter()

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      {children}
    </Dialog.Root>
  )
}
