'use client'
import { useState } from 'react'
import Input from 'react-otp-input'

import styles from './styles.module.scss'

interface TwoFactorInputProps {
  onChange(code: string): void
}

export function OTPInput({ onChange }: TwoFactorInputProps) {
  const [code, setCode] = useState('')

  const handleCodeChange = (value: string) => {
    setCode(value)
    onChange(value)
  }

  return (
    <Input
      value={code}
      onChange={handleCodeChange}
      numInputs={6}
      renderSeparator={
        <span aria-hidden="true" className={styles.otpInputSeparator} />
      }
      inputStyle={{ width: '4.125rem', height: '3.5rem' }}
      renderInput={(props) => (
        <input {...props} className={styles.otpInput} placeholder="0" />
      )}
    />
  )
}
