'use client'
import Image from 'next/image'
import { ChevronDown } from 'lucide-react'
import { useRouter } from 'next/navigation'
import * as Popover from '@radix-ui/react-popover'

import { Currency } from '@/domain/models'
import { DateFnsAdapter } from '@/infra/date'
import { setCookie } from '@/presentation/actions'
import { CURRENCY_COOKIE_KEY } from '@/presentation/constants'

import styles from './styles.module.scss'
import { useEffect, useState } from 'react'

interface CurrenciesProps {
  onlyDesktop?: boolean
}

export function Currencies({ onlyDesktop = false }: CurrenciesProps) {
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth)

  const router = useRouter()
  const date = new DateFnsAdapter()

  const isMobileDevice = deviceWidth <= 1024

  const handleCurrencyChange = async (currency: Currency) => {
    const url = new URL(window.location.href)
    url.searchParams.set('currency', currency)

    await setCookie({
      name: CURRENCY_COOKIE_KEY,
      value: currency,
      options: {
        expires: date.add(new Date(), { years: 1 }),
      },
    })

    router.push(url.href)
  }

  useEffect(() => {
    window.addEventListener('resize', () => setDeviceWidth(window.innerWidth))
  }, [])

  if (onlyDesktop && isMobileDevice) return <></>

  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <button type="button" className={styles['currencies-trigger']}>
          <span>
            BRL <span>(R$)</span>
          </span>
          <ChevronDown size={12} color="#495057" />
        </button>
      </Popover.Trigger>

      <Popover.Portal>
        <Popover.Content
          className={styles['currencies-content']}
          sideOffset={4}
        >
          <button type="button" onClick={() => handleCurrencyChange('USD')}>
            <Image
              src="/images/shared/country-icons/usa.svg"
              alt="USA"
              width={16}
              height={16}
            />

            <span>
              USD <span>(U$)</span>
            </span>
          </button>

          <button type="button" onClick={() => handleCurrencyChange('BRL')}>
            <Image
              src="/images/shared/country-icons/brazil.svg"
              alt="Brazil"
              width={16}
              height={16}
            />

            <span>
              BRL <span>(R$)</span>
            </span>
          </button>

          <button type="button" onClick={() => handleCurrencyChange('EUR')}>
            <Image
              src="/images/shared/country-icons/spanish.svg"
              alt="Spanish"
              width={16}
              height={16}
            />

            <span>
              EUR <span>(€)</span>
            </span>
          </button>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  )
}
