import { ReactNode } from 'react'

import styles from './styles.module.scss'

interface TextProps {
  children: ReactNode
}

export function CheckboxGroupText({ children }: TextProps) {
  return <div className={styles.container}>{children}</div>
}
