'use client'
import { ReactNode } from 'react'
import { DialogCloseProps } from '@radix-ui/react-dialog'

import { DialogRadixAdapter } from '@/infra/dialog'
import { condicionalStyles } from '@/presentation/helpers'

import styles from './styles.module.scss'

interface CloseProps extends DialogCloseProps {
  children: ReactNode
}

export function Close({ children, ...rest }: CloseProps) {
  const Dialog = new DialogRadixAdapter()

  return (
    <Dialog.Close
      {...rest}
      className={condicionalStyles([styles.close, rest.className], {})}
    >
      {children}
    </Dialog.Close>
  )
}
