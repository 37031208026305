'use client'
import { ReactNode } from 'react'
import { DialogRadixAdapter } from '@/infra/dialog'

interface PortalProps {
  children: ReactNode
}

export function Portal({ children }: PortalProps) {
  const Dialog = new DialogRadixAdapter()

  return <Dialog.Portal>{children}</Dialog.Portal>
}
