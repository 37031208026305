'use client'
import { DialogRadixAdapter } from '@/infra/dialog'

import styles from './styles.module.scss'

export function Overlay() {
  const Dialog = new DialogRadixAdapter()

  return <Dialog.Overlay className={styles.overlay} />
}
