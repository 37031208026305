'use client'
import { ReactNode } from 'react'

import { DialogRadixAdapter } from '@/infra/dialog'
import { condicionalStyles } from '@/presentation/helpers'

import styles from './styles.module.scss'

export interface ContentProps {
  children: ReactNode
  className?: string
}

export function Content({ children, className }: ContentProps) {
  const Dialog = new DialogRadixAdapter()

  return (
    <Dialog.Content
      className={condicionalStyles(styles.content, {
        [className || '']: !!className,
      })}
    >
      {children}
    </Dialog.Content>
  )
}
