import { ReactNode } from 'react'

import { condicionalStyles } from '@/presentation/helpers'
import { Checkbox } from '@/presentation/components/shared'

import styles from './styles.module.scss'

interface RootProps {
  isActive: boolean
  onChange(): void
  children: ReactNode
}

export function CheckboxGroupRoot({ isActive, onChange, children }: RootProps) {
  return (
    <label
      className={condicionalStyles(styles.container, {
        [styles.active]: isActive,
      })}
    >
      {children}

      <div className={styles.checkbox}>
        <Checkbox isActive={isActive} onChange={onChange} />
      </div>
    </label>
  )
}
