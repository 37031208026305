import Link from 'next/link'
import { useTranslations } from 'next-intl'
import { ArrowUpRight } from 'lucide-react'

import { Logo } from '..'
import { Profile } from './profile'
import { Currencies } from './currencies'
import { HeaderLink } from './header-link'
import { UserCookiePayload } from '@/domain/models'
import { HEADER_LINKS } from '@/presentation/constants'

import styles from './styles.module.scss'
import { MobileNav } from './mobile-nav'
import { Language } from './language'

interface HeaderProps {
  user: UserCookiePayload | null
}

export function Header({ user }: HeaderProps) {
  const t = useTranslations('components.shared.Header')

  const links = user ? HEADER_LINKS[user.role] : HEADER_LINKS.fan

  return (
    <header className={styles.header}>
      <div className={styles.left}>
        <MobileNav user={user} />

        <Link href="/" aria-label="Fanzo">
          <Logo width={32} height={32} />
        </Link>

        <nav>
          {Object.entries(links).map(([label, href], index) => (
            <HeaderLink key={index} href={href}>
              {t(label as any)}
            </HeaderLink>
          ))}
        </nav>
      </div>

      <div className={styles.right}>
        <Language onlyDesktop />

        <Currencies onlyDesktop />

        {user && <Profile user={user} />}

        {!user && (
          <nav>
            <Link href="/accounts/register" className={styles.withSeparator}>
              {t('CREATE_YOUR_ACCOUNT')}
            </Link>
            <Link href="/accounts/login" className={styles.bold}>
              {t('LOG_IN')} <ArrowUpRight size={16} color="#495057" />
            </Link>
          </nav>
        )}
      </div>
    </header>
  )
}
