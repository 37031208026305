import { ButtonHTMLAttributes } from 'react'
import Image from 'next/image'

import { condicionalStyles } from '@/presentation/helpers'

import styles from './styles.module.scss'

interface ButtonRootProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode
  isLoading?: boolean
}

export function ButtonRoot({
  children,
  type = 'button',
  isLoading = false,
  ...rest
}: ButtonRootProps) {
  return (
    <button
      {...rest}
      type={type}
      className={condicionalStyles([styles.buttonRoot, rest.className], {
        [styles.loading]: isLoading,
      })}
      disabled={rest.disabled || isLoading}
    >
      {isLoading && (
        <Image
          src="/images/shared/spinner.svg"
          alt="Spinner"
          width={20}
          height={20}
        />
      )}
      {!isLoading && children}
    </button>
  )
}
