import { forwardRef, InputHTMLAttributes } from 'react'
import { Check } from 'lucide-react'

import styles from './styles.module.scss'

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  isActive: boolean
  label?: string
  size?: number
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ isActive, label, size = 16, ...rest }, ref) => {
    return (
      <label className={styles.checkboxContainer}>
        <span
          className={isActive ? styles.active : ''}
          aria-hidden="true"
          style={{ width: size, height: size }}
        >
          {isActive && <Check size={12} color="#FFFFFF" />}
        </span>
        <input type="checkbox" {...rest} ref={ref} hidden />
        {label}
      </label>
    )
  },
)
