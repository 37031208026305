'use client'
import { useEffect, useState } from 'react'
import Link from 'next/link'
import { useTranslations } from 'next-intl'
import { AlignJustify, X } from 'lucide-react'

import { Language } from './language'
import { Currencies } from './currencies'
import { UserCookiePayload } from '@/domain/models'
import { HEADER_LINKS } from '@/presentation/constants'
import { Dialog } from '@/presentation/components/shared'

import styles from './styles.module.scss'

interface HeaderProps {
  user: UserCookiePayload | null
}

export function MobileNav({ user }: HeaderProps) {
  const t = useTranslations('components.shared.Header')
  const [dialogIsOpen, setDialogIsOpen] = useState(false)
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth)

  const isMobileDevice = deviceWidth <= 1024
  const links = user ? HEADER_LINKS[user.role] : HEADER_LINKS.fan

  const handleLinkClick = () => {
    setDialogIsOpen(false)
  }

  useEffect(() => {
    window.addEventListener('resize', () => setDeviceWidth(window.innerWidth))
  }, [])

  return (
    isMobileDevice && (
      <Dialog.Root open={dialogIsOpen} onOpenChange={setDialogIsOpen}>
        <Dialog.Trigger>
          <button type="button" className={styles['mobile-trigger']}>
            <AlignJustify size={24} color="#495057" />
          </button>
        </Dialog.Trigger>

        <Dialog.Portal>
          <Dialog.Content className={styles['mobile-content']}>
            <Dialog.Close className={styles.close}>
              <X size={24} color="#495057" />
            </Dialog.Close>

            <nav>
              {Object.entries(links).map(([label, href], index) => (
                <div key={index}>
                  <Link href={href} onClick={handleLinkClick}>
                    {t(label as any)}
                  </Link>
                </div>
              ))}
            </nav>

            <footer>
              <Language />
              <Currencies />
            </footer>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    )
  )
}
