import { forwardRef, TextareaHTMLAttributes } from 'react'

import { condicionalStyles } from '@/presentation/helpers'

import styles from './styles.module.scss'

interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label: string
  width?: string
  height?: string
  resize?: string
  error?: string
  helper?: string
}

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      label,
      width = '100%',
      height = 'auto',
      resize = 'none',
      error,
      helper,
      ...rest
    },
    ref,
  ) => {
    return (
      <label
        className={condicionalStyles(styles.textareaContainer, {
          [styles.error]: !!error,
        })}
      >
        {label}
        <textarea
          style={{ width, height, resize: resize as any }}
          {...rest}
          ref={ref}
        />
        {error && <span>{error}</span>}
        {helper && !error && <span>{helper}</span>}
      </label>
    )
  },
)
