import { Route } from '@/domain/models'

type AppRoutes = {
  public: Route[]
  private: Route[]
}

export const APP_ROUTES: AppRoutes = {
  public: [
    { name: 'home', path: '/' },
    { name: 'not-found', path: '/404' },
    { name: 'forgot-password', path: '/accounts/forgor-password' },
    { name: 'new-password', path: '/accounts/forgor-password/new-password' },
    { name: 'login', path: '/accounts/login' },
    { name: 'register', path: '/accounts/register' },
    { name: 'influencers-details', path: '/influencers/*' },
    { name: 'use-terms', path: '/terms/use-terms' },
  ],
  private: [
    {
      name: 'admin-dashboard',
      path: '/dashboard/admin',
      roles: ['internal'],
    },
    {
      name: 'comments',
      path: '/dashboard/admin/comments',
      roles: ['internal'],
    },
    {
      name: 'users',
      path: '/dashboard/admin/users',
      roles: ['internal'],
    },
    {
      name: 'influencer-dashboard',
      path: '/dashboard/influencer',
      roles: ['influencer'],
    },
    {
      name: 'influencer-profile',
      path: '/dashboard/influencer/me',
      roles: ['influencer'],
    },
    {
      name: 'influencer-connections',
      path: '/dashboard/influencer/connections',
      roles: ['influencer'],
    },
  ],
}
