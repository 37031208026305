import { forwardRef, InputHTMLAttributes } from 'react'

import { condicionalStyles } from '@/presentation/helpers'

import styles from './styles.module.scss'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string
  error?: string
  helper?: string
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    { label, error, helper, type = 'text', autoComplete = 'off', ...rest },
    ref,
  ) => {
    return (
      <label
        className={condicionalStyles(styles.inputContainer, {
          [styles.error]: !!error,
        })}
      >
        {label}
        <input type={type} {...rest} autoComplete={autoComplete} ref={ref} />
        {error && <span>{error}</span>}
        {helper && !error && <span>{helper}</span>}
      </label>
    )
  },
)
