import Image, { ImageProps } from 'next/image'

interface LogoProps extends Omit<ImageProps, 'src' | 'alt'> {
  width: number
  height: number
}

export function Logo({ width, height }: LogoProps) {
  return (
    <Image
      src="/images/shared/logo.svg"
      alt="Fanzo"
      width={width}
      height={height}
    />
  )
}
