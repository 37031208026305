import { UserRole } from '@/domain/entities'

export const HEADER_LINKS: Record<UserRole, Record<string, string>> = {
  internal: {
    HOME: '/dashboard/admin',
    USERS: '/dashboard/admin/users',
    COMMENTS: '/dashboard/admin/comments',
  },
  fan: {
    HOME: '/',
  },
  influencer: {
    HOME: '/dashboard/influencer',
    MY_PROFILE: '/dashboard/influencer/me',
    CONNECTIONS: '/dashboard/influencer/connections',
  },
}
