import { AlertCircle, BadgeCheck } from 'lucide-react'

import { CloseButton } from './close-button'
import { ToastStatus } from '@/domain/models'

import styles from './styles.module.scss'

type Data = {
  icon: JSX.Element
  color: string
  containerClassName: string
}

interface ToastProps {
  text: string
  status: ToastStatus
  handleClose(): void
}

export function Toast({ text, status, handleClose }: ToastProps) {
  const strategy: Record<typeof status, Data> = {
    error: {
      icon: <AlertCircle size={20} color="var(--red-500)" />,
      color: 'var(--red-500)',
      containerClassName: styles.error,
    },
    success: {
      icon: <BadgeCheck size={20} color="var(--green-500)" />,
      color: 'var(--green-500)',
      containerClassName: styles.success,
    },
  }

  return (
    <div
      className={`${styles.toastContainer} ${strategy[status].containerClassName}`}
    >
      <div>{strategy[status].icon}</div>

      <span>{text}</span>

      <CloseButton color={strategy[status].color} handleClose={handleClose} />
    </div>
  )
}
