import { Currency, Locale } from '@/domain/models'

export const LOCALES: Locale[] = ['en', 'es', 'pt']

export const CURRENCIES: Currency[] = ['BRL', 'EUR', 'USD']

export const CURRENCY_BY_LOCALE: Record<Locale, Currency> = {
  en: 'USD',
  es: 'EUR',
  pt: 'BRL',
}
