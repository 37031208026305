'use client'
import ReactSlider from 'react-slider'

import './slider.scss'
import styles from './styles.module.scss'

interface SliderProps {
  min: number
  max: number
  value: number
  onChange(value: number): void
  generateMinValueLabel?(state: number): string
  generateMaxValueLabel?(state: number): string
  generateCurrentValueLabel?(state: number): string
}

export function Slider({
  min,
  max,
  value,
  onChange,
  generateMinValueLabel,
  generateMaxValueLabel,
  generateCurrentValueLabel,
}: SliderProps) {
  return (
    <div className={styles.container}>
      <ReactSlider
        min={min}
        max={max}
        value={value}
        onChange={onChange}
        className="horizontal-slider"
        thumbClassName="slider-thumb"
        trackClassName="slider-track"
        renderThumb={
          generateCurrentValueLabel
            ? ({ key, ...props }, state) => (
                <div {...props} key={key}>
                  <span>{generateCurrentValueLabel(state.valueNow)}</span>
                </div>
              )
            : undefined
        }
      />

      <div className={styles.labels}>
        <div>
          {generateMinValueLabel && <span>{generateMinValueLabel(min)}</span>}
        </div>

        <div>
          {generateMaxValueLabel && <span>{generateMaxValueLabel(max)}</span>}
        </div>
      </div>
    </div>
  )
}
