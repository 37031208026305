import { SocialMediaData, SocialMediaType } from '@/domain/models'

export const SOCIAL_MEDIA_DATA: SocialMediaData[] = [
  {
    type: 'Instagram',
    isSoon: false,
  },
]

export const SOCIAL_MEDIA_ICONS: Record<SocialMediaType, string> = {
  Instagram: '/images/shared/social-media/instagram.svg',
}
