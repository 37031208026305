'use client'
import { useEffect, useState } from 'react'
import Image from 'next/image'
import { useLocale } from 'next-intl'
import { ChevronDown } from 'lucide-react'
import * as Popover from '@radix-ui/react-popover'
import { usePathname, useRouter } from 'next/navigation'

import { Locale } from '@/domain/models'

import styles from './styles.module.scss'

interface LanguageProps {
  onlyDesktop?: boolean
}

export function Language({ onlyDesktop }: LanguageProps) {
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth)

  const locale = useLocale() as Locale
  const router = useRouter()
  const pathname = usePathname()

  const isMobileDevice = deviceWidth <= 1024

  const languageStrategy: Record<Locale, string> = {
    en: '/images/shared/country-icons/usa.svg',
    es: '/images/shared/country-icons/spanish.svg',
    pt: '/images/shared/country-icons/brazil.svg',
  }

  const handleLocaleChange = async (locale: Locale) => {
    const [, , ...uri] = pathname.split('/')
    const result = ['', locale, uri.join('/')].join('/')
    router.push(result)
  }

  useEffect(() => {
    window.addEventListener('resize', () => setDeviceWidth(window.innerWidth))
  }, [])

  if (onlyDesktop && isMobileDevice) return <></>

  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <button type="button" className={styles['language-trigger']}>
          <Image
            src={languageStrategy[locale]}
            alt={locale}
            width={16}
            height={16}
          />
          <ChevronDown size={12} color="#495057" />
        </button>
      </Popover.Trigger>

      <Popover.Portal>
        <Popover.Content className={styles['language-content']} sideOffset={4}>
          <button type="button" onClick={() => handleLocaleChange('en')}>
            <Image
              src="/images/shared/country-icons/usa.svg"
              alt="USA"
              width={16}
              height={16}
            />
          </button>

          <button type="button" onClick={() => handleLocaleChange('pt')}>
            <Image
              src="/images/shared/country-icons/brazil.svg"
              alt="Brazil"
              width={16}
              height={16}
            />
          </button>

          <button type="button" onClick={() => handleLocaleChange('es')}>
            <Image
              src="/images/shared/country-icons/spanish.svg"
              alt="Spanish"
              width={16}
              height={16}
            />
          </button>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  )
}
