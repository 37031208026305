'use client'
import Link from 'next/link'
import { usePathname } from 'next/navigation'

import styles from './styles.module.scss'
import { useMemo } from 'react'

interface HeaderLinkProps {
  href: string
  children: React.ReactNode
}

export function HeaderLink({ href, children }: HeaderLinkProps) {
  const pathname = usePathname().slice(3)

  const isActive = useMemo(() => {
    const hasSlash = href[href.length - 1] === '/'
    const newHref = !hasSlash ? href + '/' : href

    return pathname + '/' === newHref
  }, [href, pathname])

  return (
    <Link href={href} className={isActive ? styles.active : ''}>
      {children}
    </Link>
  )
}
