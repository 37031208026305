'use client'
import { useTranslations } from 'next-intl'
import * as Popover from '@radix-ui/react-popover'
import { ChevronDown, LogIn, User2 } from 'lucide-react'

import { UserRole } from '@/domain/entities'
import { UserCookiePayload } from '@/domain/models'

import styles from './styles.module.scss'
import { deleteCookie, revalidatePage } from '@/presentation/actions'
import { AUTHENTICATED_USER_COOKIE_KEY } from '@/presentation/constants'
import { usePathname } from 'next/navigation'

interface ProfileProps {
  user: UserCookiePayload
}

export function Profile({ user }: ProfileProps) {
  const t = useTranslations('components.shared.Header')
  const pathname = usePathname()

  const rolesStrategy: Record<
    UserRole,
    'roles.ADMIN' | 'roles.INFLUENCER' | 'roles.FAN'
  > = {
    fan: 'roles.FAN',
    influencer: 'roles.INFLUENCER',
    internal: 'roles.ADMIN',
  }

  const handleLogout = async () => {
    deleteCookie(AUTHENTICATED_USER_COOKIE_KEY)
    await revalidatePage(pathname)
  }

  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <button type="button" className={styles['profile-trigger']}>
          <div className={styles.avatar}>
            <User2 size={20} color="#ADB5BD" />
          </div>

          <div className={styles.infos}>
            <strong>{user.name}</strong>
            <span>{t(rolesStrategy[user.role])}</span>
          </div>

          <ChevronDown size={16} color="#495057" />
        </button>
      </Popover.Trigger>

      <Popover.Portal>
        <Popover.Content className={styles['profile-content']} sideOffset={16}>
          <button type="button" onClick={handleLogout}>
            <LogIn size={20} color="var(--red-500)" />
            {t('LOG_OUT')}
          </button>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  )
}
