'use client'
import { X } from 'lucide-react'

interface CloseButtonProps {
  color: string
  handleClose(): void
}

export function CloseButton({ color, handleClose }: CloseButtonProps) {
  return (
    <button type="button" onClick={handleClose}>
      <X size={16} color={color} />
    </button>
  )
}
