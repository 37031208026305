import styles from './styles.module.scss'

interface ButtonTextProps {
  children: React.ReactNode
  color?: string
  fontWeight?: number
}

export function ButtonText({
  children,
  color = '#F1F3F5',
  fontWeight = 600,
}: ButtonTextProps) {
  return (
    <span className={styles.textContainer} style={{ fontWeight, color }}>
      {children}
    </span>
  )
}
